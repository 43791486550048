import React from "react";

import { Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ChatHeaderSkeleton from "../ChatHeaderSkeleton";

const useStyles = makeStyles(theme => ({
	chatHeader: {
		display: "flex",
		backgroundColor: "#eee",
		flex: "none",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
	},
}));

const ChatHeader = ({ loading, children }) => {
	const classes = useStyles();

	return (
		<>
			{loading ? (
				<ChatHeaderSkeleton />
			) : (
				<Card square className={classes.chatHeader}>
					{children}
				</Card>
			)}
		</>
	);
};

export default ChatHeader;

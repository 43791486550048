import React, { useState, useEffect, useReducer, useContext } from "react";
import openSocket from "socket.io-client";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";

import ChatListItem from "../ChatListItem";
import ChatsListSkeleton from "../ChatsListSkeleton";

import useChats from "../../hooks/useChats";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
	chatsListWrapper: {
		position: "relative",
		display: "flex",
		flexDirection: "column",
		overflow: "hidden",
	},

	chatsList: {
		flex: 1,
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},

	chatsListHeader: {
		color: "rgb(67, 83, 105)",
		zIndex: 2,
		backgroundColor: "white",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},

	chatsCount: {
		fontWeight: "normal",
		color: "rgb(104, 121, 146)",
		marginLeft: "8px",
		fontSize: "14px",
	},

	noChatsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noChatsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	noChatsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
}));

const reducer = (state, action) => {
	if (action.type === "LOAD_TICKETS") {
		const newChats = action.payload;

		newChats.forEach(chat => {
			const chatIndex = state.findIndex(t => t.id === chat.id);
			if (chatIndex !== -1) {
				state[chatIndex] = chat;
				if (chat.unreadMessages > 0) {
					state.unshift(state.splice(chatIndex, 1)[0]);
				}
			} else {
				state.push(chat);
			}
		});

		return [...state];
	}

	if (action.type === "RESET_UNREAD") {
		const chatId = action.payload;

		const chatIndex = state.findIndex(t => t.id === chatId);
		if (chatIndex !== -1) {
			state[chatIndex].unreadMessages = 0;
		}

		return [...state];
	}

	if (action.type === "UPDATE_TICKET") {
		const chat = action.payload;

		const chatIndex = state.findIndex(t => t.id === chat.id);
		if (chatIndex !== -1) {
			state[chatIndex] = chat;
		} else {
			state.unshift(chat);
		}

		return [...state];
	}

	if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
		const chat = action.payload;

		const chatIndex = state.findIndex(t => t.id === chat.id);
		if (chatIndex !== -1) {
			state[chatIndex] = chat;
			state.unshift(state.splice(chatIndex, 1)[0]);
		} else {
			state.unshift(chat);
		}

		return [...state];
	}

	if (action.type === "UPDATE_TICKET_CONTACT") {
		const contact = action.payload;
		const chatIndex = state.findIndex(t => t.contactId === contact.id);
		if (chatIndex !== -1) {
			state[chatIndex].contact = contact;
		}
		return [...state];
	}

	if (action.type === "DELETE_TICKET") {
		const chatId = action.payload;
		const chatIndex = state.findIndex(t => t.id === chatId);
		if (chatIndex !== -1) {
			state.splice(chatIndex, 1);
		}

		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}
};

const ChatsList = ({ status, searchParam, showAll, selectedQueueIds }) => {
	const classes = useStyles();
	const [pageNumber, setPageNumber] = useState(1);
	const [chatsList, dispatch] = useReducer(reducer, []);
	const { user } = useContext(AuthContext);

	useEffect(() => {
		dispatch({ type: "RESET" });
		setPageNumber(1);
	}, [status, searchParam, dispatch, showAll, selectedQueueIds]);

	const { chats, hasMore, loading } = useChats({
		pageNumber,
		searchParam,
		status,
		showAll,
		queueIds: JSON.stringify(selectedQueueIds),
	});

	useEffect(() => {
		if (!status && !searchParam) return;
		dispatch({
			type: "LOAD_TICKETS",
			payload: chats,
		});
	}, [chats, status, searchParam]);

	useEffect(() => {
		const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

		const shouldUpdateChat = chat =>
			(!chat.userId || chat.userId === user?.id || showAll) &&
			(!chat.queueId || selectedQueueIds.indexOf(chat.queueId) > -1);

		const notBelongsToUserQueues = chat =>
			chat.queueId && selectedQueueIds.indexOf(chat.queueId) === -1;

		socket.on("connect", () => {
			if (status) {
				if(status === "all"){
					socket.emit("joinChats", "open");
					socket.emit("joinChats", "pending");
					socket.emit("joinChats", "waiting");
				}else{
					socket.emit("joinChats", status);
				}
			} else {
				socket.emit("joinNotification");
			}
		});

		socket.on("chat", data => {
			if (data.action === "updateUnread") {
				dispatch({
					type: "RESET_UNREAD",
					payload: data.chatId,
				});
			}

			if (data.action === "update" && shouldUpdateChat(data.chat)) {
				dispatch({
					type: "UPDATE_TICKET",
					payload: data.chat,
				});
			}

			if (data.action === "update" && notBelongsToUserQueues(data.chat)) {
				dispatch({ type: "DELETE_TICKET", payload: data.chat.id });
			}

			if (data.action === "delete") {
				dispatch({ type: "DELETE_TICKET", payload: data.chatId });
			}
		});

		socket.on("appMessage", data => {
			if (data.action === "create" && shouldUpdateChat(data.chat)) {
				dispatch({
					type: "UPDATE_TICKET_UNREAD_MESSAGES",
					payload: data.chat,
				});
			}
		});

		socket.on("contact", data => {
			if (data.action === "update") {
				dispatch({
					type: "UPDATE_TICKET_CONTACT",
					payload: data.contact,
				});
			}
		});

		return () => {
			socket.disconnect();
		};
	}, [status, showAll, user, selectedQueueIds]);

	const loadMore = () => {
		setPageNumber(prevState => prevState + 1);
	};

	const handleScroll = e => {
		if (!hasMore || loading) return;

		const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

		if (scrollHeight - (scrollTop + 100) < clientHeight) {
			loadMore();
		}
	};

	return (
		<div className={classes.chatsListWrapper}>
			<Paper
				square
				name="closed"
				elevation={0}
				className={classes.chatsList}
				onScroll={handleScroll}
			>
				<List style={{ paddingTop: 0 }}>
					{chatsList.length === 0 && !loading ? (
						<div className={classes.noChatsDiv}>
							<span className={classes.noChatsTitle}>
								{i18n.t("chatsList.noChatsTitle")}
							</span>
							<p className={classes.noChatsText}>
								{i18n.t("chatsList.noChatsMessage")}
							</p>
						</div>
					) : (
						<>
							{chatsList.map(chat => (
								<ChatListItem chat={chat} key={chat.id} />
							))}
						</>
					)}
					{loading && <ChatsListSkeleton />}
				</List>
			</Paper>
		</div>
	);
};

export default ChatsList;

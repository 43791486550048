import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import ChatsManager from "../../components/ChatsManager";
import CChat from "../../components/Chat";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	chatContainer: {
		flex: 1,
		//backgroundColor: "#eee",
		padding: theme.spacing(4),
		height: `calc(100% - 48px)`,
		overflowY: "hidden",
		[theme.breakpoints.down("md")]:{
			padding: theme.spacing(2),
		},
		[theme.breakpoints.down("md")]:{
			padding: 0,
		},
	},
	chatPapper: {
		// backgroundColor: "red",
		display: "flex",
		height: "100%",
	},
	contactsWrapper: {
		display: "flex",
		flex: 4,
		height: "100%",
		flexDirection: "column",
		overflow: "hidden",
		[theme.breakpoints.down("md")]: {
			flex: 3,
		},
		[theme.breakpoints.down("sm")]: {
			flex: 12,
		},
	},
	messagessWrapper: {
		display: "flex",
		overflow: "hidden",
		flex: 8,
		height: "100%",
		flexDirection: "column",
		[theme.breakpoints.down("md")]: {
			flex: 6,
		},
		[theme.breakpoints.down("sm")]: {
			flex: 12,
			width:"100%"
		},
	},
	welcomeMsg: {
		backgroundColor: "#eee",
		display: "flex",
		justifyContent: "space-evenly",
		alignItems: "center",
		height: "100%",
		textAlign: "center",
	},
}));

const Chat = () => {
	const breakpoint = 960;
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= breakpoint);

	const updateWidth = () => {
		setIsSmallScreen(window.innerWidth <= breakpoint);
	};

	useEffect(() => {
		window.addEventListener("resize", updateWidth);
		return () => window.removeEventListener("resize", updateWidth);
	});

	const classes = useStyles();
	const { chatId } = useParams();

	return (
		<div className={classes.chatContainer}>
			<div className={classes.chatPapper}>
				<Grid container spacing={0}>
					{isSmallScreen ? (
						<>
							{chatId ? (
								<>
									<CChat />
								</>
							) :
								(
									<>
										<Grid item className={classes.contactsWrapper}>
											<ChatsManager />
										</Grid>
									</>
								)
							}
						</>
					) : (
						<>
							<Grid item className={classes.contactsWrapper}>
								<ChatsManager />
							</Grid>
							<Grid className={classes.messagessWrapper}>
								{chatId ? (
									<>
										<CChat />
									</>
								) : (
									<Paper square variant="outlined" className={classes.welcomeMsg}>
										<span>{i18n.t("chat.noChatMessage")}</span>
									</Paper>
								)}
							</Grid>
						</>
					)}
				</Grid>
			</div >
		</div >
	);
};

export default Chat;

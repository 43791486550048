import React, { useState, useEffect, useReducer, useRef, useContext } from "react";



import { isSameDay, parseISO, format } from "date-fns";
import openSocket from "socket.io-client";
import clsx from "clsx";

import { blue, green } from "@material-ui/core/colors";
import {
	Button,
	CircularProgress,
	Divider,
	IconButton,
	makeStyles,
	Tooltip,
} from "@material-ui/core";
import {
	AccessTime,
	Block,
	Done,
	DoneAll,
	ExpandMore,
	GetApp,
} from "@material-ui/icons";

import MarkdownWrapper from "../MarkdownWrapper";
import ModalImageCors from "../ModalImageCors";
import MessageOptionsMenu from "../MessageOptionsMenu";
import whatsBackground from "../../assets/wa-background.png";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
// import SendIcon from '@material-ui/icons/Send';
import ButtonWithSpinner from "../ButtonWithSpinner";
import ReplyIcon from '@material-ui/icons/Reply';
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	messagesListWrapper: {
		overflow: "hidden",
		position: "relative",
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
	},

	messagesList: {
		overflow: "hidden",
		backgroundImage: `url(${whatsBackground})`,
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
		padding: "20px 20px 20px 20px",
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},

	circleLoading: {
		color: green[500],
		position: "absolute",
		opacity: "70%",
		top: 0,
		left: "50%",
		marginTop: 12,
	},

	messageLeft: {
		marginRight: 20,
		marginTop: 2,
		minWidth: 100,
		maxWidth: 600,
		height: "auto",
		display: "block",
		position: "relative",
		"&:hover #messageActionsButton": {
			display: "flex",
			position: "absolute",
			top: 0,
			right: 0,
		},

		whiteSpace: "pre-wrap",
		backgroundColor: "#ffffff",
		color: "#303030",
		alignSelf: "flex-start",
		borderTopLeftRadius: 0,
		borderTopRightRadius: 8,
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
		paddingLeft: 5,
		paddingRight: 5,
		paddingTop: 5,
		paddingBottom: 0,
		boxShadow: "0 1px 1px #b3b3b3",
		[theme.breakpoints.down("xs")]: {
			maxWidth: "85%",
		},
	},

	quotedContainerLeft: {
		margin: "-3px -80px 6px -6px",
		overflow: "hidden",
		backgroundColor: "#f0f0f0",
		borderRadius: "7.5px",
		display: "flex",
		position: "relative",
	},

	quotedMsg: {
		padding: 10,
		height: "auto",
		display: "block",
		whiteSpace: "pre-wrap",
		overflow: "hidden",
	},

	quotedSideColorLeft: {
		flex: "none",
		width: "4px",
		backgroundColor: "#6bcbef",
	},

	messageInternal: {
		marginLeft: 20,
		marginTop: 2,
		maxWidth: 600,
		height: "auto",
		display: "block",
		position: "relative",
		"&:hover #messageActionsButton": {
			display: "flex",
			position: "absolute",
			top: 0,
			right: 0,
		},
		whiteSpace: "pre-wrap",
		backgroundColor: "rgb(251, 255, 213)",
		color: "#303030",
		alignSelf: "flex-end",
		borderRadius: 8,
		padding: 5,
		boxShadow: "0 1px 1px #b3b3b3",
		[theme.breakpoints.down("xs")]: {
			maxWidth: "85%",
		},
	},

	messageRight: {
		marginLeft: 20,
		marginTop: 2,
		minWidth: 100,
		maxWidth: 600,
		height: "auto",
		display: "block",
		position: "relative",
		"&:hover #messageActionsButton": {
			display: "flex",
			position: "absolute",
			top: 0,
			right: 0,
		},
		whiteSpace: "pre-wrap",
		backgroundColor: "#dcf8c6",
		color: "#303030",
		alignSelf: "flex-end",
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 0,
		paddingLeft: 5,
		paddingRight: 5,
		paddingTop: 5,
		paddingBottom: 0,
		boxShadow: "0 1px 1px #b3b3b3",
		[theme.breakpoints.down("xs")]: {
			maxWidth: "85%",
		},
	},

	quotedContainerRight: {
		margin: "-3px -75px 6px -6px",
		overflow: "hidden",
		backgroundColor: "#cfe9ba",
		borderRadius: "7.5px",
		display: "flex",
		position: "relative",
	},

	quotedMsgRight: {
		padding: 10,
		height: "auto",
		whiteSpace: "pre-wrap",
	},

	quotedSideColorRight: {
		flex: "none",
		width: "4px",
		backgroundColor: "#35cd96",
	},

	messageActionsButton: {
		display: "none",
		position: "relative",
		color: "#999",
		zIndex: 1,
		backgroundColor: "inherit",
		opacity: "90%",
		"&:hover, &.Mui-focusVisible": { backgroundColor: "inherit" },
	},

	messageContactName: {
		display: "flex",
		color: "#6bcbef",
		fontWeight: 500,
	},

	textContentItem: {
		overflowWrap: "break-word",
		padding: "3px 60px 6px 6px",
	},

	textContentItemDeleted: {
		fontStyle: "italic",
		color: "rgba(0, 0, 0, 0.36)",
		overflowWrap: "break-word",
		padding: "3px 80px 6px 6px",
	},

	messageMedia: {
		width: 250,
		height: 200,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
	},

	timestamp: {
		fontSize: 11,
		position: "absolute",
		bottom: 0,
		right: 5,
		color: "#999",
	},

	dailyTimestamp: {
		alignItems: "center",
		textAlign: "center",
		alignSelf: "center",
		width: "110px",
		backgroundColor: "#e1f3fb",
		margin: "10px",
		borderRadius: "10px",
		boxShadow: "0 1px 1px #b3b3b3",
	},

	dailyTimestampText: {
		color: "#808888",
		padding: 8,
		alignSelf: "center",
		marginLeft: "0px",
	},

	ackIcons: {
		fontSize: 18,
		verticalAlign: "middle",
		marginLeft: 4,
	},

	deletedIcon: {
		fontSize: 18,
		verticalAlign: "middle",
		marginRight: 4,
	},

	ackDoneAllIcon: {
		color: blue[500],
		fontSize: 18,
		verticalAlign: "middle",
		marginLeft: 4,
	},

	downloadMedia: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "inherit",
		padding: 10,
	},
}));

const reducer = (state, action) => {
	if (action.type === "LOAD_MESSAGES") {
		const messages = action.payload;
		const newMessages = [];

		messages.forEach(message => {
			const messageIndex = state.findIndex(m => m.id === message.id);
			if (messageIndex !== -1) {
				state[messageIndex] = message;
			} else {
				newMessages.push(message);
			}
		});

		return [...newMessages, ...state];
	}

	if (action.type === "ADD_MESSAGE") {
		const newMessage = action.payload;
		const messageIndex = state.findIndex(m => m.id === newMessage.id);

		if (messageIndex !== -1) {
			state[messageIndex] = newMessage;
		} else {
			state.push(newMessage);
		}

		return [...state];
	}

	if (action.type === "UPDATE_MESSAGE") {
		const messageToUpdate = action.payload;
		const messageIndex = state.findIndex(m => m.id === messageToUpdate.id);

		if (messageIndex !== -1) {
			if (messageToUpdate.isDeleted && messageToUpdate.mediaType === "internal") {
				state.splice(messageIndex, 1);
			} else {
				state[messageIndex] = messageToUpdate;
			}
		}

		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}
};

const MessagesList = ({ chatId, isGroup }) => {
	const classes = useStyles();

	const { setReplyingMessage } = useContext(ReplyMessageContext);
	const [messagesList, dispatch] = useReducer(reducer, []);
	const [pageNumber, setPageNumber] = useState(1);
	const [hasMore, setHasMore] = useState(false);
	const [loading, setLoading] = useState(false);
	const lastMessageRef = useRef();

	const [selectedMessage, setSelectedMessage] = useState({});
	const [anchorEl, setAnchorEl] = useState(null);
	const [messageOptionsMenuOpen, setMessageOptionsMenuOpen] = useState(Boolean(anchorEl));
	const currentChatId = useRef(chatId);

	useEffect(() => {
		dispatch({ type: "RESET" });
		setPageNumber(1);

		currentChatId.current = chatId;
	}, [chatId]);

	useEffect(() => {
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchMessages = async () => {
				try {
					const { data } = await api.get("/messages/" + chatId, {
						params: { pageNumber },
					});

					if (currentChatId.current === chatId) {
						dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
						setHasMore(data.hasMore);
						setLoading(false);
					}

					if (pageNumber === 1 && data.messages.length > 1) {
						scrollToBottom();
					}
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};
			fetchMessages();
		}, 500);
		return () => {
			clearTimeout(delayDebounceFn);
		};
	}, [pageNumber, chatId]);

	useEffect(() => {
		const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

		socket.on("connect", () => socket.emit("joinChatBox", chatId));

		socket.on("appMessage", data => {
			if (data.action === "create") {
				dispatch({ type: "ADD_MESSAGE", payload: data.message });
				scrollToBottom();
			}

			if (data.action === "update") {
				dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
			}

			if (data.action === "delete") {
				dispatch({ type: "DELETE_MESSAGE", payload: +data.messageId });
			}
		});

		return () => {
			socket.disconnect();
		};
	}, [chatId]);

	const scrollToBottom = () => {
		if (lastMessageRef.current) {
			lastMessageRef.current.scrollIntoView({});
		}
	};

	const handleScroll = e => {
		if (!hasMore) return;
		const { scrollTop } = e.currentTarget;

		if (scrollTop === 0) {
			document.getElementById("messagesList").scrollTop = 1;
		}

		if (loading) {
			return;
		}
	};

	const handleOpenMessageOptionsMenu = (e, message) => {
		setAnchorEl(e.currentTarget);
		setMessageOptionsMenuOpen(true);
		setSelectedMessage(message);
	};

	const handleCloseMessageOptionsMenu = e => {
		setAnchorEl(null);
		setMessageOptionsMenuOpen(false);
	};

	const checkMessageMedia = message => {
		if (message.mediaType === "image") {
			return <ModalImageCors imageUrl={message.mediaUrl} />;
		}
		if (message.mediaType === "audio") {
			return (
				<audio controls>
					<source src={message.mediaUrl} type="audio/ogg"></source>
				</audio>
			);
		}

		if (message.mediaType === "video") {
			return (
				<video
					className={classes.messageMedia}
					src={message.mediaUrl}
					controls
				/>
			);
		} else {
			return (
				<>
					<div className={classes.downloadMedia}>
						<Button
							startIcon={<GetApp />}
							color="primary"
							variant="outlined"
							target="_blank"
							href={message.mediaUrl}
						>
							Download
						</Button>
					</div>
					<Divider />
				</>
			);
		}
	};

	const checkMessageVCard = message => {
		let card = message.body.replace(/(\r\n|\n|\r)/gm, "");
		let nome = card.substring(card.indexOf("FN:") + 3, card.indexOf("TEL;"));
		let telefone = card.substring(card.indexOf("CELL;waid=") + 10);
		telefone = telefone.substring(0, telefone.indexOf(":"));
		return (
			<>
				<span className={classes.messageContactName}>
					Contato VCard
				</span>
				{"Nome: " + nome + "\n" +
					"Telefone: " + telefone + "\n\n"}
				{/* <Divider />
				<ButtonWithSpinner
					loading={loading}
					endIcon={<SendIcon />}
					size="small"
					className={classes.badgeStylePending}
				// onClick={e => handleUpdateChatStatus(e, "waiting", null)}
				> Enviar Mensagem
				</ButtonWithSpinner> */}
			</>
		);
	};

	const renderMessageAck = message => {
		if (message.ack === 0) {
			return <AccessTime fontSize="small" className={classes.ackIcons} />;
		}
		if (message.ack === 1) {
			return <Done fontSize="small" className={classes.ackIcons} />;
		}
		if (message.ack === 2) {
			return <DoneAll fontSize="small" className={classes.ackIcons} />;
		}
		if (message.ack === 3 || message.ack === 4) {
			return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
		}
	};

	const renderDailyTimestamps = (message, index) => {
		if (index === 0) {
			return (
				<span
					className={classes.dailyTimestamp}
					key={`timestamp-${message.id}`}
				>
					<div className={classes.dailyTimestampText}>
						{format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
					</div>
				</span>
			);
		}
		if (index < messagesList.length - 1) {
			let messageDay = parseISO(messagesList[index].createdAt);
			let previousMessageDay = parseISO(messagesList[index - 1].createdAt);

			if (!isSameDay(messageDay, previousMessageDay)) {
				return (
					<span
						className={classes.dailyTimestamp}
						key={`timestamp-${message.id}`}
					>
						<div className={classes.dailyTimestampText}>
							{format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
						</div>
					</span>
				);
			}
		}
		if (index === messagesList.length - 1) {
			return (
				<div
					key={`ref-${message.createdAt}`}
					ref={lastMessageRef}
					style={{ float: "left", clear: "both" }}
				/>
			);
		}
	};

	const renderMessageDivider = (message, index) => {
		if (index < messagesList.length && index > 0) {
			let messageUser = messagesList[index].fromMe;
			let previousMessageUser = messagesList[index - 1].fromMe;

			if (messageUser !== previousMessageUser) {
				return (
					<span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
				);
			}
		}
	};

	const renderQuotedMessage = message => {
		return (
			<div
				className={clsx(classes.quotedContainerLeft, {
					[classes.quotedContainerRight]: message.fromMe,
				})}
			>
				<span
					className={clsx(classes.quotedSideColorLeft, {
						[classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
					})}
				></span>
				<div className={classes.quotedMsg}>
					{!message.quotedMsg?.fromMe && (
						<span className={classes.messageContactName}>
							{message.quotedMsg?.contact?.name}
						</span>
					)}
					{message.quotedMsg.mediaUrl && checkMessageMedia(message.quotedMsg)}
					{message.quotedMsg.mediaType === "vcard" && checkMessageVCard(message.quotedMsg)}
					<div className={classes.textContentItem}>
						{message.quotedMsg.mediaType !== "vcard" && (
							<MarkdownWrapper>{message.quotedMsg.body}</MarkdownWrapper>
						)}
						<span className={classes.timestamp}>
							{format(parseISO(message.quotedMsg.createdAt), "HH:mm")}
						</span>
					</div>
				</div>
			</div>
		);
	};

	const handleReplyingMessage = message => {
		setReplyingMessage(message)
	};

	const handleLoadMorMessage = () => {
		if (!hasMore || loading) return;
		setPageNumber(pageNumber + 1);
	};

	const renderMessages = () => {
		if (messagesList.length > 0) {
			const viewMessagesList = messagesList.map((message, index) => {
				if (!message.fromMe) {
					return (
						<React.Fragment key={message.id}>
							{renderDailyTimestamps(message, index)}
							{renderMessageDivider(message, index)}
							<div className={classes.messageLeft}>
								<Tooltip title="Responder Mensagem">
									<IconButton
										aria-label="Responder Mensagem"
										variant="contained"
										size="small"
										id="messageActionsButton"
										disabled={message.isDeleted}
										className={classes.messageActionsButton}
										onClick={e => handleReplyingMessage(message)}
									>
										<ReplyIcon />
									</IconButton>
								</Tooltip>
								{isGroup && (
									<span className={classes.messageContactName}>
										{message.contact?.name}
									</span>
								)}
								<div
									className={clsx(classes.textContentItem, {
										[classes.textContentItemDeleted]: message.isDeleted,
									})}
								>
									{message.isDeleted && (
										<Block
											color="disabled"
											fontSize="small"
											className={classes.deletedIcon}
										/>
									)}
									{message.quotedMsg && renderQuotedMessage(message)}
									{message.mediaType !== "vcard" && (
										<MarkdownWrapper>{message.body}</MarkdownWrapper>
									)}
									<span className={classes.timestamp}>
										{format(parseISO(message.createdAt), "HH:mm")}
									</span>
								</div>
								{message.mediaUrl && checkMessageMedia(message)}
								{message.mediaType === "vcard" && checkMessageVCard(message)}
							</div>
						</React.Fragment>
					);
				} if (message.mediaType === "internal") {
					return (
						<React.Fragment key={message.id}>
							{renderDailyTimestamps(message, index)}
							{renderMessageDivider(message, index)}
							<div className={classes.messageInternal}>
								<Tooltip title="Apagar Anotação">
									<IconButton
										aria-label="Apagar Anotação"
										variant="contained"
										size="small"
										id="messageActionsButton"
										disabled={message.isDeleted}
										className={classes.messageActionsButton}
										onClick={e => handleOpenMessageOptionsMenu(e, message)}
									>
										<ExpandMore />
									</IconButton>
								</Tooltip>
								<div
									className={clsx(classes.textContentItem, {
										[classes.textContentItemDeleted]: message.isDeleted,
									})}
								>
									{message.isDeleted && (
										<Block
											color="disabled"
											fontSize="small"
											className={classes.deletedIcon}
										/>
									)}
									<MarkdownWrapper>{message.body}</MarkdownWrapper>
									<span className={classes.timestamp}>
										{format(parseISO(message.createdAt), "HH:mm")}
									</span>
								</div>
							</div>
						</React.Fragment>
					);
				} else {
					return (
						<React.Fragment key={message.id}>
							{renderDailyTimestamps(message, index)}
							{renderMessageDivider(message, index)}
							<div className={classes.messageRight}>
								<IconButton
									variant="contained"
									size="small"
									id="messageActionsButton"
									disabled={message.isDeleted}
									className={classes.messageActionsButton}
									onClick={e => handleOpenMessageOptionsMenu(e, message)}
								>
									<ExpandMore />
								</IconButton>
								{message.mediaUrl && checkMessageMedia(message)}
								<div
									className={clsx(classes.textContentItem, {
										[classes.textContentItemDeleted]: message.isDeleted,
									})}
								>
									{message.isDeleted && (
										<Block
											color="disabled"
											fontSize="small"
											className={classes.deletedIcon}
										/>
									)}
									{message.quotedMsg && renderQuotedMessage(message)}
									<MarkdownWrapper>{message.body}</MarkdownWrapper>
									<span className={classes.timestamp}>
										{format(parseISO(message.createdAt), "HH:mm")}
										{renderMessageAck(message)}
									</span>
								</div>
							</div>
						</React.Fragment>
					);
				}
			});
			return viewMessagesList;
		} else {
			return <div>Say hello to your new contact!</div>;
		}
	};

	return (
		<div className={classes.messagesListWrapper}>
			<ButtonWithSpinner
				style={{ backgroundColor: 'transparent' }}
				startIcon={<AddCircleOutlineIcon />}
				size="small"
				onClick={handleLoadMorMessage}
			>
				Carregar Mais Mensagens
			</ButtonWithSpinner>
			<MessageOptionsMenu
				message={selectedMessage}
				anchorEl={anchorEl}
				menuOpen={messageOptionsMenuOpen}
				handleClose={handleCloseMessageOptionsMenu}
			/>
			<div
				id="messagesList"
				className={classes.messagesList}
				onScroll={handleScroll}
			>
				{messagesList.length > 0 ? renderMessages() : []}
			</div>
			{loading && (
				<div>
					<CircularProgress className={classes.circleLoading} />
				</div>
			)}
		</div>
	);
};

export default MessagesList;

const rules = {
	user: {
		static: [],
	},

	admin: {
		static: [
			"drawer-admin-items:view",
			"chats-manager:showall",
			"user-modal:editProfile",
			"user-modal:editQueues",
			"chat-options:deleteChat",
			"contacts-page:deleteContact",
		],
	},
};

export default rules;

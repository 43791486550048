import React, { useState, createContext } from "react";

const InternalMessageContext = createContext();

const InternalMessageProvider = ({ children }) => {
	const [internalMessage, setInternalMessage] = useState(null);

	return (
		<InternalMessageContext.Provider
			value={{ internalMessage, setInternalMessage }}
		>
			{children}
		</InternalMessageContext.Provider>
	);
};

export { InternalMessageContext, InternalMessageProvider };

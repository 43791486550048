interface chartData {
    x: string,
    y: 0
}

export let initialChartOptions = {
    chart: {
        height: 350,
        type: 'heatmap',
    },
    dataLabels: {
        enabled: true
    },
    colors: ["#008FFB"],
    plotOptions: {
        heatmap: {
            shadeIntensity: 1,
            radius: 1,
            distributed: false,
            enableShades: true,
            useFillColorAsStroke: false,
        }
    },
    legend: {
        show: true,
        showForZeroSeries: false,
    },
    stroke: {
        width: 0.5,
        show: true,
        colors: ["#EEE"],
    },
    title: {
        text: 'Fluxo de mensagens semanal (quantidade por hora)'
    },
    selection: {
        enabled: true,
    }
}

export const numberToHourFormat = (num: number): string => {
    return (num < 10)
        ? `0${num}:00`
        : `${num}:00`
}

export const hourRange = (initialHour: number, lastHour: number): chartData[] => {
    let hour: number = parseInt(initialHour.toString());
    let chartDefaultData: chartData[] = [];

    while (hour <= lastHour) {
        chartDefaultData.push({
            x: numberToHourFormat(hour),
            y: 0
        });
        hour++;
    }

    return chartDefaultData;
}
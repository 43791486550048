import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { startOfWeek, endOfWeek } from "date-fns";
import moment from 'moment';
import { usersData, initialChartOptions } from "./Controller.ts";
import 'antd/dist/antd.min.css';
import styles from "./heatmap.module.css"

import { DatePicker } from 'antd';
import useMessagesByUser from "../../../hooks/useMessagesByUser";

const Filters = ({ children }) => (
	<div className={styles.filters}>{children}</div>
)

const Chart = () => {

	const [startDate, setStartDate] = useState(moment(startOfWeek(new Date())));
	const [endDate, setEndDate] = useState(moment(endOfWeek(new Date())));

	const [messages, setMessages] = useMessagesByUser({ startDate, endDate });

	const [options, setOptions] = useState(initialChartOptions);

	const [chartData, setChartData] = useState([]);

	const updateChart = () => {
		setChartData(prevState => {
			let aux = [
				{ name: "Sábado", data: usersData(messages), dayCode: 5 },
				{ name: "Sexta", data: usersData(messages), dayCode: 4 },
				{ name: "Quinta", data: usersData(messages), dayCode: 3 },
				{ name: "Quarta", data: usersData(messages), dayCode: 2 },
				{ name: "Terça", data: usersData(messages), dayCode: 1 },
				{ name: "Segunda", data: usersData(messages), dayCode: 0 },
				{ name: "Domingo", data: usersData(messages), dayCode: 6 },
			];

			for (let day of aux) {
				messages.forEach(message => {
					if (day.dayCode === message.weekday) {
						for (let user of day.data) {
							if (message.name === user.x) {
								user.y += message.quantidade;
							}
						}
					}
				});
			};

			return aux;
		});
	}
	useEffect(() => {
		updateChart();
	}, [messages, options]);

	const handleDatePick = (event) => {
		setStartDate(event[0]);
		setEndDate(event[1]);
	}

	return (
		<div id="chart">
			<Filters>
				<DatePicker.RangePicker
					defaultValue={[startDate, endDate]}
					placeholder={["Data inicial", "Data final"]}
					format={"DD/MM/YYYY"}
					onChange={(e) => { handleDatePick(e) }}
					disabledDate={false}
				/>
			</Filters>
			<ReactApexChart options={options} series={chartData} type="heatmap" height={300} />
		</div>
	);
};

export default Chart;
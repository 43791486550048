import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { format, startOfWeek, endOfWeek } from "date-fns";
import moment from 'moment';
import { hourRange, initialChartOptions, numberToHourFormat } from "./Controller.ts";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import 'antd/dist/antd.min.css';
import styles from "./heatmap.module.css"

import { TimePicker, DatePicker } from 'antd';
import Title from "../Title";
import useMessages from "../../../hooks/useMessages";
import { BatteryCharging20 } from "@material-ui/icons";

const Filters = ({ children }) => (
	<div className={styles.filters}>{children}</div>
)

const Chart = () => {

	const [startDate, setStartDate] = useState(moment(startOfWeek(new Date())));
	const [endDate, setEndDate] = useState(moment(endOfWeek(new Date())));

	const [startHour, setStartHour] = useLocalStorage('heatMap.startHour', 7);
	const [endHour, setEndHour] = useLocalStorage('heatMap.endHour', 19);

	const [messages, setMessages] = useMessages({ startDate, endDate });

	const [options, setOptions] = useState(initialChartOptions);

	const [chartData, setChartData] = useState([]);

	const updateChart = () => {
		setChartData(prevState => {
			let aux = [
				{ name: "Sábado", data: hourRange(startHour, endHour), dayCode: 5 },
				{ name: "Sexta", data: hourRange(startHour, endHour), dayCode: 4 },
				{ name: "Quinta", data: hourRange(startHour, endHour), dayCode: 3 },
				{ name: "Quarta", data: hourRange(startHour, endHour), dayCode: 2 },
				{ name: "Terça", data: hourRange(startHour, endHour), dayCode: 1 },
				{ name: "Segunda", data: hourRange(startHour, endHour), dayCode: 0 },
				{ name: "Domingo", data: hourRange(startHour, endHour), dayCode: 6 },
			];

			for (let day of aux) {
				messages.forEach(message => {
					if (day.dayCode === message.weekday) {
						for (let time of day.data) {
							if (numberToHourFormat(message.hour) === time.x) {
								time.y += message.quantidade;
							}
						}
					}
				});
			};

			return aux;
		});
	}
	useEffect(() => {
		updateChart();
	}, [messages, startHour, endHour, options]);

	const handleTimePick = (event) => {
		if (event) {
			setStartHour((event[0]._d).getHours());
			setEndHour((event[1]._d).getHours());
		} else {
			setStartHour(0);
			setEndHour(23);
		}
	}

	const handleDatePick = (event) => {
		setStartDate(event[0]);
		setEndDate(event[1]);
	}

	return (
		<div id="chart">
			<Filters>
				<DatePicker.RangePicker
					defaultValue={[startDate, endDate]}
					placeholder={["Data inicial", "Data final"]}
					format={"DD/MM/YYYY"}
					onChange={(e) => { handleDatePick(e) }}
					disabledDate={false}
				/>
				<TimePicker.RangePicker
					defaultValue={[moment().hour(startHour), moment().hour(endHour)]}
					placeholder={["Hora inicial", "Hora final"]}
					showMinute={false}
					showSecond={false}
					format={"HH:00"}
					onChange={(e) => { handleTimePick(e) }}
				/>
			</Filters>
			<ReactApexChart options={options} series={chartData} type="heatmap" height={300} />
		</div>
	);
};

export default Chart;
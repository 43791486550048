import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { Badge } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
// import MessageIcon from '@material-ui/icons/Message';
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import PhoneEnabledOutlinedIcon from '@material-ui/icons/PhoneEnabledOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";

import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";

function ListItemLink(props) {
	const { icon, primary, to, className } = props;

	const renderLink = React.useMemo(
		() =>
			React.forwardRef((itemProps, ref) => (
				<RouterLink to={to} ref={ref} {...itemProps} />
			)),
		[to]
	);

	return (
		<li>
			<ListItem button component={renderLink} className={className}>
				{icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
				<ListItemText primary={primary} />
			</ListItem>
		</li>
	);
}

const MainListItems = () => {
	const { whatsApps } = useContext(WhatsAppsContext);
	const { user } = useContext(AuthContext);
	const [connectionWarning, setConnectionWarning] = useState(false);

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (whatsApps.length > 0) {
				const offlineWhats = whatsApps.filter(whats => {
					return (
						whats.status === "qrcode" ||
						whats.status === "PAIRING" ||
						whats.status === "DISCONNECTED" ||
						whats.status === "TIMEOUT" ||
						whats.status === "OPENING"
					);
				});
				if (offlineWhats.length > 0) {
					setConnectionWarning(true);
				} else {
					setConnectionWarning(false);
				}
			}
		}, 2000);
		return () => clearTimeout(delayDebounceFn);
	}, [whatsApps]);

	return (
		<div>
			<ListItemLink
				to="/"
				primary="Dashboard"
				icon={<AssessmentOutlinedIcon />}
			/>
			<ListItemLink
				to="/chats"
				primary={i18n.t("mainDrawer.listItems.chats")}
				icon={<WhatsAppIcon />}
			/>
			<ListItemLink
				to="/contacts"
				primary={i18n.t("mainDrawer.listItems.contacts")}
				icon={<ContactPhoneOutlinedIcon />}
			/>
			{/* <ListItemLink
				to="/userChat"
				primary={"Mensagens"}
				icon={<MessageIcon />}
			/> */}
			<Can
				role={user.profile}
				perform="drawer-admin-items:view"
				yes={() => (
					<>
						<Divider />
						{/*<ListSubheader inset>
							{i18n.t("mainDrawer.listItems.administration")}
						</ListSubheader>*/}
						<ListItemLink
							to="/connections"
							primary={i18n.t("mainDrawer.listItems.connections")}
							icon={
								<Badge badgeContent={connectionWarning ? "!" : 0} color="error">
									<PhoneEnabledOutlinedIcon />
								</Badge>
							}
						/>
						<ListItemLink
							to="/users"
							primary={i18n.t("mainDrawer.listItems.users")}
							icon={<PeopleAltOutlinedIcon />}
						/>
						<ListItemLink
							to="/queues"
							primary={i18n.t("mainDrawer.listItems.queues")}
							icon={<AccountTreeOutlinedIcon />}
						/>
					</>
				)}
			/>
			<Divider />
			<Typography variant="body2" color="textSecondary" align="center">
				{"Versão 20.23.00.01"}
			</Typography>	
		</div>
	);
};

export default MainListItems;

import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import openSocket from "socket.io-client";
import clsx from "clsx";

import { Paper, makeStyles } from "@material-ui/core";

import ContactDrawer from "../ContactDrawer";
import MessageInput from "../MessageInput";
import ChatHeader from "../ChatHeader";
import ChatInfo from "../ChatInfo";
import ChatActionButtons from "../ChatActionButtons";
import MessagesList from "../MessagesList";
import api from "../../services/api";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import { InternalMessageProvider } from "../../context/InternalMessage/InternalMessageContext";

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		height: "100%",
		width: "100%",
		position: "relative",
		overflow: "hidden",
	},

	mainWrapper: {
		flex: 1,
		height: "100%",
		display: "flex",
		flexDirection: "column",
		overflow: "hidden",
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		borderLeft: "0",
		marginRight: -drawerWidth,
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},

	mainWrapperShift: {
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: 0,
	},
}));

const Chat = () => {
	const { chatId } = useParams();
	const history = useHistory();
	const classes = useStyles();

	const [drawerOpen, setDrawerOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [contact, setContact] = useState({});
	const [chat, setChat] = useState({});

	useEffect(() => {
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchChat = async () => {
				try {
					const { data } = await api.get("/chats/" + chatId);

					setContact(data.contact);
					setChat(data);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};
			fetchChat();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [chatId, history]);

	useEffect(() => {
		const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

		socket.on("connect", () => socket.emit("joinChatBox", chatId));

		socket.on("chat", data => {
			if (data.action === "update") {
				setChat(data.chat);
			}

			if (data.action === "delete") {
				toast.success("Chat deleted sucessfully.");
				history.push("/chats");
			}
		});

		socket.on("contact", data => {
			if (data.action === "update") {
				setContact(prevState => {
					if (prevState.id === data.contact?.id) {
						return { ...prevState, ...data.contact };
					}
					return prevState;
				});
			}
		});

		return () => {
			socket.disconnect();
		};
	}, [chatId, history]);

	const handleDrawerOpen = () => {
		setDrawerOpen(true);
	};

	const handleDrawerClose = () => {
		setDrawerOpen(false);
	};

	return (
		<div className={classes.root} id="drawer-container">
			<Paper
				variant="outlined"
				elevation={0}
				className={clsx(classes.mainWrapper, {
					[classes.mainWrapperShift]: drawerOpen,
				})}
			>
				<ChatHeader loading={loading}>
					<ChatInfo
						contact={contact}
						chat={chat}
						onClick={handleDrawerOpen}
					/>
					<ChatActionButtons chat={chat} />
				</ChatHeader>
				<ReplyMessageProvider>
					<InternalMessageProvider>
						<MessagesList
							chatId={chatId}
							isGroup={chat.isGroup}
						></MessagesList>
						<MessageInput chatStatus={chat.status} />
					</InternalMessageProvider>
				</ReplyMessageProvider>
			</Paper>
			<ContactDrawer
				open={drawerOpen}
				handleDrawerClose={handleDrawerClose}
				contact={contact}
				loading={loading}
			/>
		</div>
	);
};

export default Chat;

import React from "react";

import MUIPaper from "@material-ui/core/Paper";
import MUIContainer from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import styles from "./index.module.css";

import MessagesChart from "./ChartMessages/heatmap";
import MessagesByUserChart from "./ChartMessagesByUser/heatmap";

const Container = ({ children }) => (
	<MUIContainer className={styles.container}>{children}</MUIContainer>
);

const Paper = ({ children }) => (
	<MUIPaper className={styles.paper}>{children}</MUIPaper>
);

const Dashboard = () => {
	return (
		<div>
			<Container>
				<Grid>
					<Paper>
						<MessagesChart />
					</Paper>
					<Paper>
						<MessagesByUserChart />
					</Paper>
				</Grid>
				<Grid>

				</Grid>
			</Container>
		</div>
	);
};

export default Dashboard;

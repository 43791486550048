import React, { useEffect, useRef } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { blue, green, yellow } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";

import MarkdownWrapper from "../MarkdownWrapper";

const useStyles = makeStyles(theme => ({
	chat: {
		position: "relative",
	},

	pendingChat: {
		cursor: "unset",
	},

	noChatsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},

	noChatsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noChatsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	contactNameWrapper: {
		display: "flex",
		justifyContent: "space-between",
	},

	lastMessageTime: {
		justifySelf: "flex-end",
	},

	closedBadge: {
		alignSelf: "center",
		justifySelf: "flex-end",
		marginRight: 32,
		marginLeft: "auto",
	},

	contactLastMessage: {
		paddingRight: 20,
	},

	newMessagesCount: {
		alignSelf: "center",
		marginRight: 8,
		marginLeft: "auto",
	},

	badgeStyle: {
		color: "white",
		backgroundColor: green[500],
	},

	badgeStyleOpen: {
		color: "white",
		marginRight: 20,
		whiteSpace: "noWrap",
		backgroundColor: blue[500],
	},

	badgeStylePending: {
		color: "black",
		marginRight: 20,
		backgroundColor: yellow[500],
	},


	acceptButton: {
		position: "absolute",
		left: "50%",
	},

	chatQueueColor: {
		flex: "none",
		width: "8px",
		height: "100%",
		position: "absolute",
		top: "0%",
		left: "0%",
	},
}));

const ChatListItem = ({ chat }) => {
	const classes = useStyles();
	const history = useHistory();
	const { chatId } = useParams();
	const isMounted = useRef(true);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);
	const handleSelectChat = id => {
		history.push(`/chats/${id}`);
	};

	return (
		<React.Fragment key={chat.id}>
			<ListItem
				dense
				button
				onClick={e => {
					handleSelectChat(chat.id);
				}}
				selected={chatId && +chatId === chat.id}
				className={clsx(classes.chat, {
					[classes.pendingChat]: chat.status === "pending",
				})}
			>
				<ListItemAvatar>
					<Avatar src={chat?.contact?.profilePicUrl} />
				</ListItemAvatar>
				<ListItemText
					disableTypography
					primary={
						<span className={classes.contactNameWrapper}>
							<Typography
								noWrap
								component="span"
								variant="body2"
								color="textPrimary"
							>
								{chat.contact.name}
							</Typography>
							{chat.status === "closed" && (
								<Badge
									className={classes.closedBadge}
									badgeContent={"resolvido"}
									classes={{
										badge: classes.badgeStyle,
									}}
								/>
							)}
							{chat.status === "open" && (
								<Badge
									className={classes.closedBadge}
									badgeContent={"em atendimento"}
									classes={{
										badge: classes.badgeStyleOpen,
									}}

								/>
							)}
							{chat.status === "waiting" && (
								<Badge
									className={classes.closedBadge}
									badgeContent={"aguardando"}
									classes={{
										badge: classes.badgeStylePending,
									}}
								/>
							)}
							{chat.status === "pending" && (
								<Badge
									className={classes.closedBadge}
									badgeContent={"aberto"}
									color="error"
								/>
							)}
							{chat.lastMessage && (
								<Typography
									className={classes.lastMessageTime}
									component="span"
									variant="body2"
									color="textSecondary"
								>
									{isSameDay(parseISO(chat.updatedAt), new Date()) ? (
										<>{format(parseISO(chat.updatedAt), "HH:mm")}</>
									) : (
										<>{format(parseISO(chat.updatedAt), "dd/MM/yyyy")}</>
									)}
								</Typography>
							)}
						</span>
					}
					secondary={
						<span className={classes.contactNameWrapper}>
							<Typography
								className={classes.contactLastMessage}
								noWrap
								component="span"
								variant="body2"
								color="textSecondary"
							>
								{chat.lastMessage ? (
									<MarkdownWrapper>{chat.lastMessage}</MarkdownWrapper>
								) : (
									<br />
								)}
							</Typography>

							<Badge
								className={classes.newMessagesCount}
								badgeContent={chat.unreadMessages}
								classes={{
									badge: classes.badgeStyle,
								}}
							/>
						</span>
					}
				/>
			</ListItem>
			<Divider variant="inset" component="li" />
		</React.Fragment>
	);
};

export default ChatListItem;

import React, { useEffect, useRef, useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import Divider from '@material-ui/core/Divider';
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import TransferChatModal from "../TransferChatModal";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";

const ChatOptionsMenu = ({ chat, menuOpen, handleClose, anchorEl, isSmallScreen, handleStatusUpdate, user }) => {
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [transferChatModalOpen, setTransferChatModalOpen] = useState(false);
	const isMounted = useRef(true);
	const history = useHistory();

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleDeleteChat = async () => {
		try {
			await api.delete(`/chats/${chat.id}`);
		} catch (err) {
			toastError(err);
		}
	};

	const handleMarkUnreadChat = async () => {
		try {
			toast.info("Chat Marcado como nao lido");
			await api.post(`/chats/markunread/${chat.id}`);
			handleClose();
			history.push(`/chats`);
		} catch (err) {
			toastError(err);
		}
	};

	const handleRechargeChat = async () => {
		try {
			toast.info("Executando em instantes ...");
			await api.get(`/messages/recharge/${chat.id}`);
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};

	const handleOpenConfirmationModal = e => {
		setConfirmationOpen(true);
		handleClose();
	};

	const handleOpenTransferModal = e => {
		setTransferChatModalOpen(true);
		handleClose();
	};

	const handleCloseTransferChatModal = () => {
		if (isMounted.current) {
			setTransferChatModalOpen(false);
		}
	};

	return (
		<>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={menuOpen}
				onClose={handleClose}
			>
				{isSmallScreen &&
					<div>
						{chat.status === "open" ? (
							<MenuItem
								onClick={e => {
									handleStatusUpdate(e, "waiting", user?.id);
									handleClose();
								}}
							>
								Colocar em Aguardando
							</MenuItem>
						) : (
							<MenuItem
								onClick={e => {
									handleStatusUpdate(e, "open", user?.id);
									handleClose();
								}}
							>
								Atender
							</MenuItem>
						)}
						<Divider />
						<MenuItem
							onClick={e => {
								handleStatusUpdate(e, "closed", user?.id);
								handleClose();
							}}
						>
							Resolver
						</MenuItem>
						<Divider />
					</div>
				}
				<MenuItem onClick={handleRechargeChat}>
					{i18n.t("chatOptionsMenu.recharge")}
				</MenuItem>
				<Divider />
				<MenuItem onClick={handleMarkUnreadChat}>
					Marcar Como Não Lido
				</MenuItem>
				<Divider />
				<MenuItem onClick={handleOpenTransferModal}>
					{i18n.t("chatOptionsMenu.transfer")}
				</MenuItem>
				<Divider />
				<MenuItem onClick={handleOpenConfirmationModal}>
					{i18n.t("chatOptionsMenu.delete")}
				</MenuItem>
			</Menu>
			<ConfirmationModal
				title={`${i18n.t("chatOptionsMenu.confirmationModal.title")}${chat.id
					} ${i18n.t("chatOptionsMenu.confirmationModal.titleFrom")} ${chat.contact.name
					}?`}
				open={confirmationOpen}
				onClose={setConfirmationOpen}
				onConfirm={handleDeleteChat}
			>
				{i18n.t("chatOptionsMenu.confirmationModal.message")}
			</ConfirmationModal>
			<TransferChatModal
				modalOpen={transferChatModalOpen}
				onClose={handleCloseTransferChatModal}
				chatid={chat.id}
			/>
		</>
	);
};

export default ChatOptionsMenu;

interface chartData {
    x: string,
    y: 0
}

export let initialChartOptions = {
    chart: {
        height: 350,
        type: 'heatmap',
    },
    dataLabels: {
        enabled: true
    },
    colors: ["#00CB8B"],
    plotOptions: {
        heatmap: {
            shadeIntensity: 1,
            radius: 1,
            distributed: false,
            enableShades: true,
            useFillColorAsStroke: false,
        }
    },
    legend: {
        show: true,
        showForZeroSeries: false,
    },
    stroke: {
        width: 0.5,
        show: true,
        colors: ["#EEE"],
    },
    title: {
        text: 'Quantidade de mensagens enviadas por usuário'
    },
    selection: {
        enabled: true,
    }
}

export const usersData = (usersList: any): chartData[] => {
    let chartDefaultData: chartData[] = [];

    for (let user of usersList) {
        if (!chartDefaultData.find((item) => item.x === user.name)) {
            chartDefaultData.push({
                x: user.name,
                y: 0 // quantidade de mensagens iniciais
            });
        }
    }

    return chartDefaultData;
}
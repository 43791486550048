import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { MoreVert, HourglassEmpty } from "@material-ui/icons";
import DoneIcon from '@material-ui/icons/Done';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ChatOptionsMenu from "../ChatOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { green, yellow } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(1),
		},
	},
	badgeStyle: {
		color: "white",
		backgroundColor: green[500],
	},
	badgeStylePending: {
		color: "black",
		marginRight: 20,
		backgroundColor: yellow[500],
	},
}));

const ChatActionButtons = ({ chat }) => {
	const breakpoint = 656;
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= breakpoint);

	const updateWidth = () => {
		setIsSmallScreen(window.innerWidth <= breakpoint);
	};

	useEffect(() => {
		window.addEventListener("resize", updateWidth);
		return () => window.removeEventListener("resize", updateWidth);
	});

	const classes = useStyles();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	const chatOptionsMenuOpen = Boolean(anchorEl);
	const { user } = useContext(AuthContext);

	const handleOpenChatOptionsMenu = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseChatOptionsMenu = e => {
		setAnchorEl(null);
	};
	const handleUpdateChatStatus = async (e, status, userId) => {
		setLoading(true);
		try {
			await api.put(`/chats/${chat.id}`, {
				status: status,
				userId: userId || null,
			});

			setLoading(false);
			if (status === "open") {
				history.push(`/chats/${chat.id}`);
			} else {
				history.push("/chats");
			}
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	return (
		<div className={classes.actionButtons}>
			{chat.status === "closed" && (
				<ButtonWithSpinner
					loading={loading}
					startIcon={<SettingsBackupRestoreIcon />}
					size="small"
					onClick={e => handleUpdateChatStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.reopen")}
				</ButtonWithSpinner>
			)}
			{chat.status === "open" && (
				<>
					{!isSmallScreen && <>
						<ButtonWithSpinner
							loading={loading}
							startIcon={<HourglassEmpty />}
							size="small"
							className={classes.badgeStylePending}
							onClick={e => handleUpdateChatStatus(e, "waiting", null)}
						>
							{i18n.t("messagesList.header.buttons.return")}
						</ButtonWithSpinner>
						<ButtonWithSpinner
							loading={loading}
							size="small"
							startIcon={<DoneIcon />}
							variant="contained"
							className={classes.badgeStyle}
							onClick={e => handleUpdateChatStatus(e, "closed", user?.id)}
						>
							{i18n.t("messagesList.header.buttons.resolve")}
						</ButtonWithSpinner>
					</>}
					<IconButton onClick={handleOpenChatOptionsMenu}>
						<MoreVert />
					</IconButton>
					<ChatOptionsMenu
						chat={chat}
						anchorEl={anchorEl}
						menuOpen={chatOptionsMenuOpen}
						handleStatusUpdate={handleUpdateChatStatus}
						user={user}
						handleClose={handleCloseChatOptionsMenu}
						isSmallScreen={isSmallScreen}
					/>
				</>
			)}
			{(chat.status === "pending" || chat.status === "waiting") && (
				<>
					{!isSmallScreen && <>
						<ButtonWithSpinner
							loading={loading}
							size="small"
							variant="contained"
							color="primary"
							onClick={e => handleUpdateChatStatus(e, "open", user?.id)}
						>
							{i18n.t("messagesList.header.buttons.accept")}
						</ButtonWithSpinner>
						<ButtonWithSpinner
							loading={loading}
							size="small"
							startIcon={<DoneIcon />}
							variant="contained"
							className={classes.badgeStyle}
							onClick={e => handleUpdateChatStatus(e, "closed", user?.id)}
						>
							{i18n.t("messagesList.header.buttons.resolve")}
						</ButtonWithSpinner>
					</>}
					<IconButton onClick={handleOpenChatOptionsMenu}>
						<MoreVert />
					</IconButton>
					<ChatOptionsMenu
						chat={chat}
						anchorEl={anchorEl}
						menuOpen={chatOptionsMenuOpen}
						handleStatusUpdate={handleUpdateChatStatus}
						user={user}
						handleClose={handleCloseChatOptionsMenu}
						isSmallScreen={isSmallScreen}
					/>
				</>
			)}
		</div>
	);
};

export default ChatActionButtons;

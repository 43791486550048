import { useState, useEffect } from "react";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const useMessages = ({
	startDate,
	endDate,
}) => {
	const [loading, setLoading] = useState(true);
	const [messages, setMessages] = useState([]);

	useEffect(() => {
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchMessages = async () => {
				try {
					const { data } = await api.get("/messages", {
						params: {
							startDate,
							endDate
						},
					});
					setMessages(data.messages);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};
			fetchMessages();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [
		startDate, endDate
	]);

	return [ messages, setMessages ];
};

export default useMessages;
